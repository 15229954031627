import React from 'react';

import { Stack, Typography } from '@mui/material';

import useStyles from './styles';

interface Props {
    status: string | number;
    description?: React.ReactNode;
    className?: string;
}

const ErrorPage: React.FC<Props> = ({ status, description, className }) => {
    const { classes, cx } = useStyles();
    return (
        <Stack className={cx(classes.root, className)}>
            <Typography variant="h1" className={classes.status}>
                {status}
            </Typography>
            <Typography variant="subtitle1" className={classes.description}>
                {description}
            </Typography>
        </Stack>
    );
};

export default ErrorPage;
