import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ErrorPage' })({
    root: {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 16
    },
    status: {
        color: '#F5726A',
        fontSize: 96,
        fontWeight: 700,
        lineHeight: '120%',
        textShadow: '8px 8px 4px #9e9e9e61'
    },
    description: {
        textTransform: 'uppercase',
        fontSize: 24,
        fontWeight: 500,
        color: '#8E8E8E'
    }
});
